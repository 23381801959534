/* eslint-disable no-inner-declarations */
import React, { Component, createRef } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import DocumentMeta from 'react-document-meta'
import jsQR from 'jsqr'
import { checkMedia } from '../_helpers'

class QR extends Component {
    canvas = createRef()

    state = {
        error: '',
        height: 0,
        width: 0,
        redirect: null,
    }

    componentDidMount() {
        this.StartScanner(this.DisplayQRMessage, this.canvas.current)
    }

    componentWillUnmount() {
        this.video.removeEventListener('loadeddata', this.loadedData)
    }

    DisplayQRMessage = (msg) => {
        const urlRegex = /^https:\/\/hmtechhub\.com\/room\/(?<room>[0-9]{2})\/$/
        const found = msg.match(urlRegex)
        let redirect
        if (found) {
            const room = found.groups.room
            redirect = `/room/${room}/`
        } else if (msg === 'https://qrco.de/bbOF3n') {
            redirect = `/iframe/${encodeURIComponent(encodeURIComponent(msg))}/`
        }

        this.setState({ redirect })
    }

    StartScanner = async (QRDataCallback, canvas) => {
        this.video = document.createElement('video')

        // Required for iOS compatability
        this.video.setAttribute('autoplay', '')
        this.video.setAttribute('muted', '')
        this.video.setAttribute('playsinline', '')

        // Request access to rear-facing camera 'ENVIRONMENT CAMERA'

        checkMedia()
        const device = await navigator.mediaDevices.getUserMedia({ video:{facingMode: 'environment' }})
        this.video.srcObject = device
        this.video.onloadedmetadata = () => { this.video.play() }

        this.loadedData = () => {
            // Camera is producing data, begin reading
            if(this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
                const width  = this.video.videoWidth
                const height = this.video.videoHeight
                this.setState({ height, width })
                // Copy one video frame per screen refresh (60Hz ON MOST PHONES)
                const ctx = canvas.getContext('2d')
                const CopyVideoFrame = () => {
                    ctx.drawImage(this.video, 0, 0, this.state.width, this.state.height)
                    const imageData = ctx.getImageData(0, 0, this.state.width, this.state.height)
                    const code = jsQR(imageData.data, imageData.width, imageData.height, {
                        inversionAttempts: 'dontInvert',
                    })
                    if(code && code.data && QRDataCallback) {
                        QRDataCallback(code.data)
                    }
                    requestAnimationFrame(CopyVideoFrame)
                }
                requestAnimationFrame(CopyVideoFrame)
            }
        }

        this.video.addEventListener('loadeddata', this.loadedData)
    }

    render() {
        const meta = {
            title: `QR Scanner | Tech Hub`,
        }

        if (this.state.redirect) return <Redirect to={ this.state.redirect } push />

        return (
            <QRContainer>
                <DocumentMeta { ...meta } />
                <Scanner ref={ this.canvas } height={ this.state.height } width={ this.state.width }/>
            </QRContainer>
        )
    }
}

const QRContainer = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
`

const Scanner = styled.canvas`
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    position: fixed;
    top: 0;
`

export { QR }
