import React, { Component, createRef } from 'react'
import styled from 'styled-components'
import DocumentMeta from 'react-document-meta'

class Iframe extends Component {
    canvas = createRef()

    state = {
        iframe: {},
    }

    componentDidMount() {
        let url = this.props.computedMatch.params.url
        if (url.includes('%2F')) {
            url = encodeURIComponent(url)
        }
        url = url.replace(/\./g, encodeURIComponent('%2E'))
        fetch(`/api/iframe/${url}/`).then(res => res.json()).then(iframe => this.setState({ iframe }))
    }

    render() {
        const meta = {
            title: `Tech Hub`,
        }
        return (
            <IframeContainer>
                <DocumentMeta { ...meta } />
                <IframeComponent src={ this.state.iframe.url }></IframeComponent>
            </IframeContainer>
        )
    }
}

const IframeContainer = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
`

const IframeComponent = styled.iframe`
    position: fixed;
    top: 70px;
    width: 100vw;
    height: calc(100vh - 70px);
    -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
`

export { Iframe }
