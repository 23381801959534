import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { history } from './_helpers'
import {
    Header,
    QR,
    Room,
    Iframe,
} from './Components'
import { devices } from './Components/Common'
import './css/normalize.css'
import './css/App.scss'
import colors from './css/colors.scss'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import { sendError } from './_services'

ReactGA.initialize('UA-2686154-11', { debug: false })

class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
    }

    componentDidCatch(error, info) {
        if (process.env.NODE_ENV === 'production') {
            sendError(
                error.message,
                info.componentStack,
                window.location.href
            )
        }
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    render() {
        if (this.state.hasError) return <h1>Something went wrong.</h1>
        return this.props.children
    }
}

const PageRoute = ({ component: Component, path, exact, ...props }) =>
    <Route
        exact={ exact }
        path={ path }
        history={ history }
        render={ () => <Component { ...props } /> }
    />

class App extends React.Component {
    state = {
        GAStarted: false,
        location: '',
    }

    componentDidMount() {
        this.sendGA(window.location.pathname)
        this.setState({ location: window.location.pathname })
        history.listen((location) => {
            this.sendGA(location.pathname)
            this.setState({ location: location.pathname })
        })
    }

    sendGA = (pathname) => {
        if (process.env.NODE_ENV === 'production') ReactGA.pageview(pathname)
    }

    render() {
        return (
            <ErrorBoundary>
                <Router history={ history }>
                    <MainPage>
                        { this.state.location !== '/' ? <Route path='*' component={ Header } /> : '' }
                        <PageWrap noheader={ this.state.location === '/' }>
                            <Switch>
                                <PageRoute exact path='/' component={ QR } />
                                <PageRoute exact path='/room/:roomNumber/' component={ Room } />
                                <PageRoute exact path='/iframe/:url/' component={ Iframe } />
                            </Switch>
                        </PageWrap>
                    </MainPage>
                </Router>
            </ErrorBoundary>
        )
    }
}

const PageWrap = styled.main`
    padding-top: ${({ noheader }) => noheader ? 0 : 80}px;
    flex: 1;
    @media ${devices.tablet} {
        ${({ noheader }) => noheader ? 0 : 60}px;
    }
`

const MainPage = styled.div`
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background: linear-gradient(
        160deg,
        ${colors.light_blue} 0%,
        ${colors.gradient_blue1} 10%,
        ${colors.gradient_blue2} 50%,
        ${colors.dark_blue} 100%
    );

    ${devices.small} {
        background: linear-gradient(
            145deg,
            ${colors.light_blue} 0%,
            ${colors.gradient_blue1} 40%,
            ${colors.mobile_gradient1} 70%,
            ${colors.mobile_gradient_end} 100%
        );
    }
`

export { App }
